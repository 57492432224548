import React from "react";

function Project({ name, img, stack, description, githubLink, projectLink }) {
  return (
    <div className="project">
      <h2 className="project__title">{name}</h2>

      <img className="project__img" src={img} alt="project image" />

      <div className="project__stack">
        {stack.map((item, i) => {
          if (item.split("/").length === 1)
            return <i key={i} className={item}></i>;

          return (
            <img
              key={i}
              className="fiirebase"
              src={item}
              alt="project screenshot"
            />
          );
        })}
      </div>

      <p className="projectDescription">{description}</p>

      <div className="project__btns">
        {githubLink ? (
          <a href={githubLink} className="project__btn" target="blank">
            <i className="fab fa-github"></i> Github
          </a>
        ) : (
          ""
        )}

        <a href={projectLink} className="project__btn" target="_blank">
          <i className="fas fa-eye"></i> Preview
        </a>
      </div>
    </div>
  );
}

export default Project;
