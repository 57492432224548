import { useEffect, useState } from "react";
import Header from "./Header/Header";
import responsiteIcon from "./images/responsive-design.svg";
import devIcon from "./images/development.svg";
import appIcon from "./images/app-design.svg";
import amazonClone from "./images/amazon-clone.png";
import spotifyClone from "./images/spotify-clone.png";
import chatImg from "./images/chat.png";
import blogImg from "./images/blog-finder.png";
import firebaseImg from "./images/icons8-firebase.svg";
import graphqlIcon from "./images/icons8-graphql.svg";
import reduxIcon from "./images/icons8-redux.svg";
import me from "./images/me-removebg-preview.png";
import mongoIcon from "./images/icons8-mongodb.svg";
import { cloudFuncLink } from "./config";
import Tile from "./Tile/Tile";
import "./App.css";
import Project from "./Project/Project";

function App() {
  const [userInputs, setUserInputs] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);

  // Input validations
  function validateInputs(inputs) {
    const { name, email, message } = inputs;
    let isError = false;
    const _errors = {};
    if (name.replace(/ /gi, "") === "") {
      _errors.name = "Name cannot be empty.";
      isError = true;
    }

    if (email.replace(/ /gi, "") === "") {
      _errors.email = "Email cannot be empty.";
      isError = true;
    } else {
      const regEx = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
      if (!email.match(regEx)) {
        _errors.email = "Email must be valid";
        isError = true;
      }
    }

    if (message.replace(/ /gi, "") === "") {
      _errors.message = "Message cannot be empty.";
      isError = true;
    } else if (message.length < 10) {
      _errors.message = "Message is too short";
      isError = true;
    }

    setErrors(_errors);
    return [isError, _errors];
  }

  // Form submission
  const sendMessage = (e) => {
    e.preventDefault();
    if (isSending) {
      return;
    }

    const [isError, inputErrors] = validateInputs(userInputs);

    if (isError) {
      let temp = { ...userInputs };
      let inputs = { ...userInputs };
      Object.keys(inputErrors).forEach((key) => {
        inputs[key] = inputErrors[key];
        setUserInputs(inputs);
      });

      setTimeout(() => {
        setErrors({});
        setUserInputs(temp);
      }, 2500);

      return;
    }

    setIsSending(true);
    fetch(
      `${cloudFuncLink}?name=${userInputs.name}&email=${userInputs.email}&message=${userInputs.message}`,
      {
        mode: "no-cors",
      }
    ).then(() => {
      setTimeout(() => {
        setIsSending(false);
        setUserInputs({ name: "", email: "", message: "" });
      }, 200);
    });
  };

  return (
    <div className="app">
      <Header />

      <section className="app__hero">
        <div className="app__heroIntro">
          <h1 className="app__heroIntroTitle">
            Hi, I'm <span className="name">Nduduzo Shabalala</span>.
          </h1>

          <h3 className="app__heroIntroSubtitle">
            I'm one of those developers who signed a life-long contract with
            endless learning.
          </h3>

          <a href="/#about" className="app__heroIntroBtn">
            Who am I <i className="far fa-question-circle"></i>
          </a>
        </div>
      </section>

      <section className="app__about" id="about">
        <h2 className="app__aboutTitle">About</h2>

        <div className="app__aboutBody">
          <aside className="app__aboutBodyBio">
            <h1 className="bio__title">Hey, lets know Nduduzo</h1>
            <p className="bio__description">
              I am a web developer with a strong passion in programming. I love
              creating web products and I put a lot of pride in my work and I
              enjoy seeing my good work reaching other people.
            </p>
            <p className="bio__description">
              I am open for jobs and collaborations. I mostly work with React,
              CSS3, JavaScript, SCSS, NodeJS and MongoDB. You can view my work
              below and <a href="/#contact"> get in touch</a> if you are pleased
              with it.
            </p>

            {/* <a href="/resume" className="bio__resume">
              View my resume...
            </a> */}
          </aside>

          <aside className="app__aboutMe">
            <img className="app__aboutMeImg" src={me} alt="nduduzo shabalala" />
          </aside>
        </div>

        <div className="app__aboutFooter">
          <h1>I Work With</h1>

          <div className="skills">
            <span>
              <i className="fab fa-5x fa-html5"></i> <br /> HTML5
            </span>
            <span>
              <i className="fab fa-5x fa-css3"></i> <br /> CSS3
            </span>
            <span>
              <i className="fab fa-5x fa-js-square"></i> <br /> JavaScript
            </span>
            <span>
              <i className="fab fa-5x fa-sass"></i> <br /> SASS
            </span>
            <span>
              <i className="fab fa-5x fa-react"></i> <br /> React
            </span>
            <span>
              <img style={{ width: 70 + "px" }} src={reduxIcon} alt="icon" />{" "}
              <br /> Redux
            </span>
            <span>
              <i className="fab fa-5x fa-node"></i> <br /> Node.JS
            </span>
            <span>
              <i className="fab fa-5x fa-git-alt"></i> <br /> Git
            </span>
            <span>
              <img style={{ width: 70 + "px" }} src={graphqlIcon} alt="icon" />{" "}
              <br /> GraphQL
            </span>
            <span>
              <img style={{ width: 70 + "px" }} src={mongoIcon} alt="icon" />{" "}
              <br /> MongoDB
            </span>
          </div>
        </div>
      </section>

      <section className="app__projects" id="projects">
        <h2 className="app__projectsTitle">My Work</h2>

        <div className="app__projectsAttribution">
          Icons made by{" "}
          <a
            href="https://www.flaticon.com/authors/flat-icons"
            title="Flat Icons"
          >
            Flat Icons
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
        <div className="app__projectsAttribution">
          Icons made by{" "}
          <a href="https://www.freepik.com" title="Freepik">
            Freepik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>

        <div className="app__projectsTiles">
          <Tile
            img={responsiteIcon}
            title="Responsiveness"
            summary="I build one size fit all products with mobile-first approach"
          />

          <Tile
            img={appIcon}
            title="Progressive Web Apps"
            summary="Bringing native app functionality to the web with PWAs?"
          />

          <Tile
            img={devIcon}
            title="Fullstack JavaScript"
            summary="Fullstack JS web apps powered by the awesome MERN Stack"
          />
        </div>

        <div className="app__projectsWork">
          <Project
            name="01. Blog Post Finder"
            img={blogImg}
            stack={[
              "fab fa-4x fa-html5",
              "fab fa-4x fa-css3",
              "fab fa-4x fa-js-square",
            ]}
            description="A web app to search for tech blogs (articles). This App uses the
            basic web tech stack which is HTML, CSS3 (SCSS) and Javascript.
            The searching of blog (articles) is achieved with the help of
            Google's Programmable Search Engine JSON API."
            githubLink="https://github.com/volubleogre664/blog-finder.git"
            projectLink="https://find-blogs-1614938844000.web.app/"
          />

          <Project
            name="02. Chat Application"
            img={chatImg}
            stack={[
              "fab fa-4x fa-css3",
              "fab fa-4x fa-react",
              "fab fa-4x fa-node",
            ]}
            description="This is a awesome chat application made with the MERN Stack. This
            app features the great features of react. From hooks to state
            management with React Context API. All of the code is in
            JavaScript ES6. In the backend it is powered by express and
            MongoDB to bring user Authentication. I made use of Pusher to make
            it a realtime chatting app. This was also a pair programming project"
            githubLink="https://github.com/volubleogre664/chat-app.git"
            projectLink="https://chat-app-4a595.web.app/"
          />

          <Project
            name="03. Spotify Clone"
            img={spotifyClone}
            stack={[
              "fab fa-4x fa-css3",
              "fab fa-4x fa-react",
              "fab fa-4x fa-js-square",
            ]}
            description="This is a full frontend build using the latest technolgy from
            react. There is react hooks and the React Context API. With the
            help of the spotify-web-api-js library this beccamee a fully
            functional clone of the spotify app. The authentication is handled
            by spotify and the user is redirected back to the app once
            authenticated."
            githubLink="https://github.com/volubleogre664/spotify-clone.git"
            projectLink="https://spotify-clone-df28d.web.app/"
          />

          <Project
            name="04. Amazon Clone"
            img={amazonClone}
            stack={["fab fa-4x fa-css3", "fab fa-4x fa-react", firebaseImg]}
            description="This one is a full firebase application. It utilises
            authentication from firebase and the user data is stored in
            firebase's firestore. This was to implement a simple yet powerful
            e-commerce functionality. This app has full basket functionality.
            The state of the app is managed with the React Context API(similar
            to Redux just a bit less powerful)."
            projectLink="https://challenge-e828d.web.app/"
          />
        </div>
      </section>

      <section className="app__contact" id="contact">
        <h2 className="app__contactTitle">Contact</h2>

        <h3 className="app__contactSubtitle">Get in touch and let's talk.</h3>

        <form className="app__contactForm" onSubmit={sendMessage}>
          <input
            type="text"
            value={userInputs.name}
            name="name"
            placeholder="Name(s)"
            required={true}
            disabled={isSending}
            className={`app__contactFormInput ${
              errors?.name ? "error" : isSending ? "success" : ""
            }`}
            onChange={(e) =>
              setUserInputs({ ...userInputs, name: e.target.value })
            }
          />

          <input
            type="email"
            value={userInputs.email}
            name="email"
            placeholder="Email"
            required={true}
            disabled={isSending}
            className={`app__contactFormInput ${
              errors?.email ? "error" : isSending ? "success" : ""
            }`}
            onChange={(e) =>
              setUserInputs({ ...userInputs, email: e.target.value })
            }
          />

          <textarea
            value={userInputs.message}
            name="message"
            className={`app__contactFormInput ${
              errors?.message ? "error" : isSending ? "success" : ""
            }`}
            placeholder="Your Message"
            required={true}
            disabled={isSending}
            onChange={(e) =>
              setUserInputs({ ...userInputs, message: e.target.value })
            }
          ></textarea>

          <button
            type="submit"
            className={`app__contactFormBtn ${isSending && "sent"}`}
          >
            {isSending ? <i className="fas fa-check"></i> : "send"}
          </button>
        </form>
      </section>

      <footer className="app__footer">
        <div className="app__footerContainer">
          <span className="iconContainer">
            <a href="https://github.com/volubleogre664/" target="_blank">
              <i className="fab fa-4x fa-github"></i>
            </a>
          </span>

          <span className="iconContainer">
            <a href="https://t.co/wUXGjFaVTo?amp=1" target="_blank">
              <i className="fab fa-4x fa-linkedin"></i>
            </a>
          </span>

          <span className="iconContainer">
            <a href="https://twitter.com/theReactDude" target="_blank">
              <i className="fab fa-4x fa-twitter"></i>
            </a>
          </span>
        </div>

        <div className="app__footerDisclaimer">
          2021 &copy; Nduduzo Shabalala
        </div>
      </footer>
    </div>
  );
}

export default App;
